/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, {
    useEffect, useRef,
} from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    object, string, shape, arrayOf,
} from 'prop-types';
import { compose } from 'recompose';
import { useLazyQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import GraphqlTemplateBuilder from './GraphqlTemplateBuilder';
import { getUserSubmittedProductFilterZipcode } from '../../../../state/ducks/App/App-Selectors';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
import { usePrev } from '../../../helpers/hooks/usePrev';
// helpers, redux, hooks
import { GRAPHQL_ENV } from '../../../gql';
import findCategoryById from '../../../gql/queries/findCategoryById';
import useExperimentServiceAttributes from '../../../helpers/experimentService/useExperimentServiceAttributes';

const determineProductOptions = (userSubmittedZip, pageNumber, pageSize = 12) => {
    if (userSubmittedZip !== '') {
        return { zipCode: userSubmittedZip, pageSize, page: pageNumber };
    }
    return { pageSize, page: pageNumber };
};
const GraphqlTemplateContainer =  ({
    templateData, pageData,  userSubmittedZip, brand,
}) => {
    if (templateData?.category?.id) {
        const pageCount = useRef(2);
        const filterPropChanged = useRef(false);
        const prevPath = usePrev(pageData?.path);
        const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });
        const CATEGORY_PRODUCT_QUERY = findCategoryById(isGraphqlTargetingEnabled);
        const [getProducts, {
            loading, error, data, fetchMore, refetch,
        }] = useLazyQuery(CATEGORY_PRODUCT_QUERY);
        const isMounted = useRef(false); // see useEffect comment for explination of this useRef
        useEffect(() => {
        // useEffect normally runs on mount, unmount, and when any values given in the array param are modified
        // this query should not run on component mount. Only if one of the array values is modified
        // Setting a ref to false initiailly and only executing the query if the ref returns true
        // Ensures that the query will not run on mount when useEffect is first triggered
            if (isMounted.current && refetch) {
            // This query only runs if a filter prop is changed
                pageCount.current = 1;
                filterPropChanged.current = true;
                refetch({
                    variables: {
                        productOptions: determineProductOptions(userSubmittedZip, pageCount.current),
                        brand: brand?.domain,
                        environment: GRAPHQL_ENV,
                        id: templateData?.category?.id,
                        locale: 'en-us',
                        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                    },
                    context,
                });
                pageCount.current = 2;
            } else if (isMounted.current) {
                getProducts({
                    variables: {
                        productOptions: determineProductOptions(userSubmittedZip, pageCount.current),
                        brand: brand?.domain,
                        environment: GRAPHQL_ENV,
                        id: templateData?.category?.id,
                        locale: 'en-us',
                        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                    },
                    context,
                });
            } else {
                isMounted.current = true;
            }
        }, [userSubmittedZip]);
        useEffect(() => {
        // this useEffect is to prevent a bug where the user goes to a category page and paginates
        // then goes to a different category page and paginates
        // then goes back to the first page.
        // Before this fix users would see the second page products when they returned to the first page.
        // This was because the results of findCategoryByUrl (in GraphqlCategoryBuilder)
        // were cached when returning to the first page, and so there was no loading state in cateogrybuilder
        // and so this component never unmounts. Because it never unmounts the data returned from the lazy query on the second page still existed
        // and was displaying instead of the first page products.
        // the fix is to run this query automatically if going to a category page the user has already visited
        // there is no performance degredation because the results of this query will already be cached.
            if (pageData.path !== prevPath && prevPath !== undefined && refetch) {
                pageCount.current = 1;
                refetch({
                    variables: {
                        productOptions: determineProductOptions(userSubmittedZip, pageCount.current),
                        brand: brand.domain,
                        environment: GRAPHQL_ENV,
                        id: templateData?.category?.id,
                        locale: 'en-us',
                        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                    },
                    context,
                });
                pageCount.current = 2;
            } else if (pageData.path !== undefined
                && pageData?.contentType === 'template_page_sales'
                && pageData?.contentType === 'template_collection_subnav_image_banner'
                && pageData?.contentType === 'template_collections_subnav_banner') { // added only which are using load more
                pageCount.current = 1;
                getProducts({
                    variables: {
                        productOptions: determineProductOptions(userSubmittedZip, pageCount.current),
                        brand: brand.domain,
                        environment: GRAPHQL_ENV,
                        id: templateData?.category?.id,
                        locale: 'en-us',
                        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                    },
                    context,
                });
                pageCount.current = 2;
            }
        }, [pageData?.path]);
        const determineQuery = () => {
        // this fuction is used to determine which query to run when a user scrolls to the end of currently loaded products
        // fetchMore is a function returned from the useLazyQuery hook.
        // Since the the query in this container is not run by default on cat page load the fetchMore function might not exist initially
        // If it does, begin the pagination process
        // if not, run the query for the first time
            if (fetchMore) {
            // the below condiitonal is used to mathematically determine the current page.
            // it is necessary to do this because of apollo's caching.
            // if the user goes to a category and paginates then clicks on a product and then goes back to that category
            // then they scroll to paginate again apollo will return all of the previously fetched products as part of that first
            // product query because they are all in apollo's cache.
            // this can lead to an issue where the pageCount is 3 becasue it's after the first pagination call but apollo is returning
            // more products than the first three pages. To prevent this the actual page number is calculated based on number of
            // products that are currently displayed
                if (data?.findCategoryById?.products?.length) {
                    pageCount.current = Math.ceil(data?.findCategoryById?.products?.length / 12) + 1;
                } else {
                    pageCount.current += 1;
                }
                // // the below conditional is to prevent a refetch of the last page of products in the above scenario
                // // if there are
                // if (12 * (totalPages.current - 1) < data?.findCategoryById?.products?.length && data?.findCategoryById?.products?.length * totalPages.current * 12) {
                //     return;
                // }
                fetchMore({
                    variables: {
                        productOptions: determineProductOptions(userSubmittedZip, pageCount.current),
                        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                    },
                    context,
                });
            } else {
            // if this conditional section is activated it means the user is trying to load the second page of products
            // the pageCount is set to one, but to prevent only returning the products already on the page
            // determineProductOptions is called with an optional flag that causes the number of products returned to double
            // If a normal page size is 12 products, this will return 24.
                pageCount.current = 1;
                getProducts({
                    variables: {
                        productOptions: determineProductOptions(userSubmittedZip, pageCount.current),
                        brand: brand?.domain,
                        environment: GRAPHQL_ENV,
                        id: templateData?.category?.id,
                        locale: 'en-us',
                        ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                    },
                    context,
                });
                // the pageCount is set to two and then is incrememnted by 1 below to end up at 3.
                // when the user scrolls to the bottom of the products the next query will return the normal number of products
                // and begin from page 3
                pageCount.current = 2;
            }
        };
        if (loading) {
            return <div style={{ height: '100vh' }} />;
        }
        if (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                query: CATEGORY_PRODUCT_QUERY,
                component: 'GraphqlTemplateContainer.js',
                message: 'Error loading data from Graphql',
                env: GRAPHQL_ENV,
                error,
            });
            return null;
        }
        if (data) {
            return (
                <GraphqlTemplateBuilder
                    onLoadMore={determineQuery}
                    templateData={templateData}
                    page={pageData}
                    productsData={data?.findCategoryById?.products || []}
                    brand={brand}
                />
            );
        }
        return (
            templateData
            && (
                <GraphqlTemplateBuilder
                    onLoadMore={determineQuery}
                    templateData={templateData}
                    page={pageData}
                    brand={brand}
                />
            )
        );
    }
    return (
        templateData
        && (
            <GraphqlTemplateBuilder
                onLoadMore={() => null}
                templateData={templateData}
                page={pageData}
                brand={brand}
            />
        )
    );
};
const mapStateToProps = (state) => ({
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
});
GraphqlTemplateContainer.propTypes = {
    templateData: shape({
        brand: string.isRequired,
        content: shape({
            entries: arrayOf(
                shape({
                    seo: shape({
                        breadcrumb: arrayOf(
                            shape({
                                title: string.isRequired,
                                href: string.isRequired,
                            }),
                        ),
                    }),
                }),
            ),
            category: shape({
                products: arrayOf(
                    shape({
                        image: shape({
                            name: string,
                            altText: string,
                        }),
                        name: string,
                        seo: shape({
                            url: string,
                        }),
                        partNumber: string,
                    }),
                ),
            }),
        }),
    }).isRequired,
    pageData: shape({
        code: string.isRequired,
        contentType: string.isRequired,
        path: string.isRequired,
        type: string.isRequired,
    }).isRequired,
    userSubmittedZip: string.isRequired,
    brand: object.isRequired,
};
const enhance = compose(
    withPageView({
        pageViewSuccessTest: () => true,
    }),
    withRouter,
    connect(mapStateToProps),
);
export default enhance(GraphqlTemplateContainer);
